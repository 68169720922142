import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import FeatureSectionLight from '../components/featureSectionLight';
import FeatureCardsSection from '../components/featureCardsSection';

import DevopsIcon from '../../assets/svg/uslugi-devops.svg';
import ProjektowanieIcon from '../../assets/svg/projektowanie-oprogramowania.svg';
import CloudIcon from '../../assets/svg/cloud.svg';
import AgileIcon from '../../assets/svg/agile.svg';
import MicroservicesIcon from '../../assets/svg/microservices.svg';
import NadzorIcon from '../../assets/svg/nadzor.svg';
import StartupsIcon from '../../assets/svg/startupy.svg';
import ERPIcon from '../../assets/svg/erp.svg';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const TechPage = () => {
  const data = useStaticQuery(graphql`
        query ConsultingImages {
            kompleksowa: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

  return (
    <SubLayout
      title={(
        <>
          Consulting
          {' '}
          <span>IT</span>
        </>
              )}
      subtitle="Usługi doradztwa informatycznego"
      img="/img/bg-systemy-erp.jpg"
    >
      <SEO title="Doradztwo informatyczne - consulting IT w iqcode Software House" description="Świadczymy wyspecjalizowane usługi doradztwa w zakresie projektowania oprogramowania, nadzoru nad projektami informatycznymi oraz ewaluacji startupów SaaS." />
      <FeatureSectionLight
        title={(
          <>
            Doradztwo
            {' '}
            <span>Informatyczne</span>
          </>
                      )}
        img={(
          <div style={{ padding: '10%' }}>
            <Img
              alt="komepleksowe uslugi doradztwa informatycznego"
              fluid={data.kompleksowa.childImageSharp.fluid}
              imgStyle={{ width: 'auto' }}
            />
          </div>
                      )}
        desc={(
          <>
            <p>
              Świadczymy usługi doradztwa informatycznego w licznych obszarach specjalizacji. Przeprowadzamy analizy potrzeb klienta oraz projektujemy i dokumentujemy nowoczesne rozwiązania informatyczne. Obszerny zbiór technologii, którymi się posługujemy pozwala nam na świadczenie kompleksowych usług doradczych odnośnie projektów łączących różne dziedziny IT.
            </p>
            <p>
              Do obszarów naszej specjalizacji należy między innymi doradztwo związane z ewaluacją startupów technologicznych, projektowanie oprogramowania oraz nadzór nad prowadzonymi projektami programistycznymi dla zabezpieczenia interesów klienta.
            </p>
          </>
                      )}
        link="Porozmawiajmy"
        to="/kontakt/"
      />
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Porozmawiajmy o Twoim
            {' '}
            <span>projekcie</span>
            !
          </h3>
          <Link className="btn" to="/kontakt/">
            Kontakt
          </Link>
        </div>
      </div>
      <FeatureCardsSection
        lg
        title={(
          <>
            Obszary
            {' '}
            <span>Specjalizacji</span>
          </>
                  )}
        subtitle="W których świadczymy usługi consultingowe"
        cards={[
          {
            title: 'Ewaluacja Startupów',
            desc:
  <>
    Usługi świadczone na rzecz inwestorów na etapie
    {' '}
    <i>Due diligence</i>
    {' '}
    startupów technologicznych działających w modelu SaaS obejmujące oszacowanie kosztów realizacji produktu cyfrowego, identyfikację ewentualnego długu technologicznego oraz ocenę skalowalności oprogramowania.
  </>,
            icon: <StartupsIcon />,
          },
          {
            title: 'Chmura Obliczeniowa',
            desc:
  <>
    Doradztwo związane z migracją z własnej infrastruktury serwerowej do chmury publicznej (w tym Amazon Web Services oraz Microsoft Azure) oraz projektowanie rozwiązań chmury hybrydowej (
    <i>hybrid cloud</i>
    , łączenie infrastruktury
    {' '}
    <i>on-premise</i>
    {' '}
    z chmurą publiczną).
  </>,
            icon: <CloudIcon />,
          },
          {
            title: 'Usługi DevOps',
            desc:
  <>
    Usługi związane z tworzeniem oraz utrzymaniem środowisk ciągłej integracji i wdrażania (
    <i>continuous integration and deployment</i>
    , CI/CD), konteneryzacja aplikacji oraz przeprowadzanie wdrożeń w środowisku uruchamiania systemów rozproszonych
    {' '}
    <strong>Kubernetes</strong>
    {' '}
    z zapewnieniem monitoringu oraz agregacji dzienników aplikacji (Prometheus oraz EFK stack).
  </>,
            icon: <DevopsIcon />,
          },
          {
            title: 'Metodyki Zwinne',
            desc:
  <>
    Usługi obejmujące wdrażanie oraz personalizację metodyk zwinnych (w tym metodyki
    {' '}
    <strong>Scrum</strong>
    ) w zespołach programistycznych klienta z opcjonalnym, tymczasowym lub permanentnym, przejęciem roli (Proxy) Product Owner lub Scrum Master przez iqcode.
  </>,
            icon: <AgileIcon />,
          },
          {
            title: 'Architektura Mikrousług',
            desc:
  <>Doradztwo oraz prace projektowe związane z transformacją monolitycznych aplikacji serwerowych do architektury mikrousług (microservices architecture) z wykorzystaniem rozwiązań komunikacji synchronicznej (Istio, gRPC) oraz asynchronicznej (RabbitMQ, Apache Kafka) w systemie rozproszonym.</>,
            icon: <MicroservicesIcon />,
          },
          {
            title: 'Systemy CRM/ERP',
            desc:
  <>Usługi consultingowe związane z analizą potrzeb przedsiębiorstwa klienta w kontekście wdrożenia systemów CRM/ERP obejmujące analizę i modelowanie procesów biznesowych, sporządzanie dokumentacji wymagań funkcjonalnych oraz doradztwo w zakresie doboru odpowiedniego systemu.</>,
            icon: <ERPIcon />,
          },
          {
            title: 'Projetkowanie Oprogramowania',
            desc:
  <>
    Usługi związane z projektowaniem rozwiązań informatycznych obejmujące warsztaty
    {' '}
    <i>Design Sprint</i>
    , tworzenie projektów architekturalnych aplikacji, formułowanie wymagań funkcjonalnych oraz pozafunkcjonalnych oraz ich dokumentację w formie umożliwiającej klientowi pozyskiwanie ofert
    {' '}
    <i>Fixed-Price</i>
    {' '}
    od wykonawców.
  </>,
            icon: <ProjektowanieIcon />,
          },
          {
            title: 'Nadzór nad projektami IT',
            desc:
  <>Nadzór nad pracami programistycznymi realizowanymi przez zewnętrznych wykonawców mający na celu zabezpieczenie interesów klienta obejmujący analizę dokumentacji projektowej, badanie jakości kodu źródłowego, testowanie produktu pod kątem spełniania wymagań funkcjonalnych i pozafunkcjonalnych oraz wsparcie klienta przy odbiorze prac programistycznych.</>,
            icon: <NadzorIcon />,
          },
        ]}
      />
      <AllServices />
    </SubLayout>
  );
};

export default TechPage;
